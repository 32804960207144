<app-title
  title="Marka Bilgileri"
  subtext="Marka bilgilerini aşağıdaki formlar yardımıyla girebilirsiniz."
  icon="brand"
></app-title>

<form class="row" (submit)="save()">
  <div class="col-lg-4 col-md-12">
    <mat-card class="mb2 p2">
      <app-divider content="Genel Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-12 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Adi</mat-label>
            <input
              matInput
              name="names"
              [(ngModel)]="data.name"
              #ctrl="ngModel"
              required
            />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider></app-divider>

      <div class="row mt1">
        <div class="col-lg-6 col-md-6">
          <button
            type="button"
            mat-raised-button
            color="warn"
            class="w100"
            *ngIf="data.id"
            (click)="delete()"
          >
            Markayi Sil
          </button>
        </div>
        <div class="col-lg-6 col-md-6">
          <button mat-raised-button color="accent" class="w100" value="Submit">
            Markayi Kaydet
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</form>
