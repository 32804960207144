import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { CustomerDto } from '../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private req: RequestService) { }

  async getAll(): Promise<CustomerDto[]> {
    return await this.req.get('/Customer/GetAll').then(res => res.Data);
  }

  async get(id: string): Promise<CustomerDto> {
    return await this.req.get(`/Customer/${id}`).then(res => res.Data);
  }

  async save(data: CustomerDto): Promise<CustomerDto> {
    return await this.req.post('/Customer', data);
  }
}
