<form (submit)="save()" *ngIf="data">
  <fieldset class="row mt1">
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Satıcı Adı</mat-label>
        <input
          matInput
          name="Name"
          [(ngModel)]="data.name"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Key</mat-label>
        <input
          matInput
          name="apiKey"
          [(ngModel)]="data.apiKey"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Secret</mat-label>
        <input
          matInput
          name="secretKey"
          [(ngModel)]="data.secretKey"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Yetki Adı</mat-label>
        <input
          matInput
          name="roleName"
          [(ngModel)]="data.roleName"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Yetki Şifresi</mat-label>
        <input
          matInput
          name="rolePassword"
          [(ngModel)]="data.rolePassword"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <!-- <mat-form-field class="w100">
        <mat-label>Gönderim Tarihi</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.shippingDate"
          #ctrl="ngModel"
          required
        />
      </mat-form-field> -->

      <mat-form-field class="w100">
        <mat-label>Gönderim Tarihi</mat-label>
        <mat-select
          name="shippingDate"
          [(ngModel)]="data.shippingDate"
          #ctrl="ngModel"
          required >
          <mat-option value="today"> Aynı gün </mat-option>
          <mat-option value="tomorrow"> Ertesi gün </mat-option>
          <mat-option value="2-3days"> 2-3 Gün içinde </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <!-- <mat-form-field class="w100">
        <mat-label>Lokasyon</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.location"
          #ctrl="ngModel"
          required
        />
      </mat-form-field> -->

      <mat-form-field class="w100">
        <mat-label>Lokasyon</mat-label>
        <mat-select
          name="location"
          [(ngModel)]="data.location"
          #ctrl="ngModel"
          required >
          <mat-option *ngFor="let item of cities" [value]="item.code">
            {{item.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <!-- <mat-form-field class="w100">
        <mat-label>Kargo Firması</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.cargoCompany"
          #ctrl="ngModel"
          required
        />
      </mat-form-field> -->

      <mat-form-field class="w100">
        <mat-label>Kargo Firması</mat-label>
        <mat-select
          name="cargoCompany"
          [(ngModel)]="data.cargoCompany"
          #ctrl="ngModel"
          required >
          <mat-option value="aras"> Aras Kargo </mat-option>
          <mat-option value="borusan"> Borusan Lojistik </mat-option>
          <mat-option value="dhl"> DHL  </mat-option>
          <mat-option value="fedex"> FedEx  </mat-option>
          <mat-option value="fillo"> Fillo Lojistik </mat-option>
          <mat-option value="mng"> MNG Kargo </mat-option>
          <mat-option value="narpost"> Narpost Kargo </mat-option>
          <mat-option value="ptt"> Ptt Kargo </mat-option>
          <mat-option value="surat"> Sürat Kargo </mat-option>
          <mat-option value="tnt"> TNT </mat-option>
          <mat-option value="tezel"> Tezel Lojistik </mat-option>
          <mat-option value="ups"> UPS </mat-option>
          <mat-option value="varan"> Varan kargo </mat-option>
          <mat-option value="yurtici"> Yurtiçi Kargo </mat-option>
          <mat-option value="diger"> Diğer </mat-option>
        </mat-select>
      </mat-form-field>

    </div>
    <div class="col-lg-6 col-md-12 m0">
      <!-- <mat-form-field class="w100">
        <mat-label>İndirim Günü</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.saleDay"
          #ctrl="ngModel"
          required
        />
      </mat-form-field> -->

      <mat-form-field class="w100">
        <mat-label>Satışta Kalma Süresi</mat-label>
        <mat-select
          name="saleDay"
          [(ngModel)]="data.saleDay"
          #ctrl="ngModel"
          required >
          <mat-option [value]=30> 30 </mat-option>
          <mat-option [value]=60> 60 </mat-option>
          <mat-option [value]=180> 180 </mat-option>
          <mat-option [value]=360> 360 </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Mağaza Durumu</mat-label>

        <mat-select
          name="Status"
          [(ngModel)]="data.status"
          #ctrl="ngModel"
          required
        >
          <mat-option [value]="true"> Aktif </mat-option>
          <mat-option [value]="false"> Pasif </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-6 col-md-12 m0">
      <!-- <mat-form-field class="w100">
        <mat-label></mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.shippingFee"
          #ctrl="ngModel"
          required
        />
      </mat-form-field> -->

      <mat-form-field class="w100">
        <mat-label>Gönderim Ücreti</mat-label>
        <mat-select
          name="shippingFee"
          [(ngModel)]="data.shippingFee"
          #ctrl="ngModel"
          required >
          <mat-option value="B"> Alıcı Öder </mat-option>
          <mat-option value="S"> Satıcı Öder </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div *ngIf="data.shippingFee =='B'" class="col-lg-6 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Gönderim Tutarı</mat-label>
        <input
          matInput
          name="shippingAmount"
          [(ngModel)]="data.shippingAmount"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Önü)</mat-label>
      <quill-editor style="width:100%" name="productFirstDescription" [(ngModel)]="data.productFirstDescription"></quill-editor>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Sonu)</mat-label>
      <quill-editor style="width:100%" name="productLastDescription" [(ngModel)]="data.productLastDescription"></quill-editor>
    </div>
  </fieldset>

  <app-divider></app-divider>

  <div class="row mt1">
    <div class="col-lg-12 col-md-12">
      <button mat-raised-button color="primary" class="w100" value="Submit">
        Ayarlari Kaydet
      </button>
    </div>
  </div>
</form>
