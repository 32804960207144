<app-title
  title="Ürün Genel Bilgileri"
  subtext="Ürününüzün genel bilgilerini aşağıdaki formlar yardımıyla girebilirsiniz."
  icon="orders"
></app-title>

<form class="row" (submit)="save()">
  <div class="col-lg-8 col-md-12">
    <mat-card class="mb2 p2">
      <app-divider content="Genel Bilgiler"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-12 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Başlık</mat-label>
            <input
              matInput
              placeholder="Maksimum 150 Karakter"
              name="name"
              [(ngModel)]="data.product.name"
              #ctrl="ngModel"
              maxlength="150"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Alt Başlık</mat-label>
            <input
              matInput
              placeholder="Maksimum 65 Karakter"
              name="slug"
              [(ngModel)]="data.product.slug"
              #ctrl="ngModel"
              maxlength="65"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Marka</mat-label>
            <mat-select
              name="brandId"
              [(ngModel)]="data.product.brandId"
              required
            >
              <mat-option value="00000000-0000-0000-0000-000000000000"
                >Marka Seç</mat-option
              >
              <mat-option *ngFor="let brand of brands" [value]="brand.value">
                {{ brand.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Kategori-</mat-label>
            <mat-select
              name="categoryId"
              [(ngModel)]="data.product.categoryId"
              required
            >
              <mat-option value="00000000-0000-0000-0000-000000000000"
                >Kategori Seç</mat-option
              >
              <mat-option
                *ngFor="let category of categories"
                [value]="category.value"
              >
                {{ category.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Satış Durumu</mat-label>
            <mat-select
              name="salesStatus"
              [(ngModel)]="data.product.salesStatus"
              required
            >
              <mat-option [value]="true">Satışa açık</mat-option>
              <mat-option [value]="false">Satışa kapalı</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Barkod/GTIN</mat-label>
            <input
              matInput
              name="barcode"
              [(ngModel)]="data.product.barcode"
              #ctrl="ngModel"
            />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider content="Satış Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-12 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Fatura Başlığı</mat-label>
            <input
              matInput
              placeholder="Fatura kaleminde ürün adı maskelemek için kullanılır"
              name="invoiceTitle"
              [(ngModel)]="data.product.invoiceTitle"
              #ctrl="ngModel"
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Stok</mat-label>
            <input
              matInput
              name="stock"
              [(ngModel)]="data.product.amount"
              #ctrl="ngModel"
              mask="0*"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Stok Kodu</mat-label>
            <input
              matInput
              name="code"
              [(ngModel)]="data.product.code"
              #ctrl="ngModel"
            />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider content="Medya/İçerik Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-12 col-md-12 m0 editorHolder">
          <quill-editor style="width:100%" name="description" [(ngModel)]="data.product.description"></quill-editor>
        </div>

        <div class="col-lg-12 col-md-12 m0 mt1">
          <mat-label>Resimler</mat-label>
          <ul class="imagesHolder">
            <li class="buttonHolder">
              <label for="newimageinput" class="new-image-handler"
                >Yeni <br />Resim Yükle</label
              >
            </li>
            <li>
              <ul class="imageGallery">
                <li *ngFor="let image of images; let imageIndex = index">
                  <button type="button" (click)="removeImage(imageIndex)">
                    x
                  </button>
                  <img [src]="image" alt="" />
                </li>
              </ul>
            </li>
          </ul>
          <input
            #newImageInput
            name="newimage"
            [(ngModel)]="newimage"
            #ctrl="ngModel"
            id="newimageinput"
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            style="display: none"
            (change)="handleNewImage()"
          />
        </div>
      </fieldset>

      <app-divider></app-divider>

      <div class="row mt1">
        <div class="col-lg-3 col-lg-offset-6 col-md-6">
          <button mat-raised-button color="warn" class="w100">Ürünü Sil</button>
        </div>
        <div class="col-lg-3 col-md-6">
          <button mat-raised-button color="accent" class="w100" value="Submit">
            Ürünü Kaydet
          </button>
        </div>
      </div>
    </mat-card>
  </div>
  <div class="col-lg-4 col-md-12">
    <mat-card class="mb2 p2">
      <app-divider content="Fiyat Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>KDV Oranı</mat-label>
            <input
              matInput
              name="kdv"
              [(ngModel)]="data.product.kdv"
              #ctrl="ngModel"
              mask="percent"
              suffix="%"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Alış Fiyatı</mat-label>
            <input
              matInput
              name="purchasePrice"
              [(ngModel)]="data.product.purchasePrice"
              #ctrl="ngModel"
              mask="0*.00"
              prefix="₺"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Piyasa(Liste) Fiyatı</mat-label>
            <input
              matInput
              name="listPrice"
              [(ngModel)]="data.product.listPrice"
              #ctrl="ngModel"
              mask="0*.00"
              prefix="₺"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Satış Fiyatı</mat-label>
            <input
              matInput
              name="price"
              [(ngModel)]="data.product.price"
              #ctrl="ngModel"
              mask="0*.00"
              prefix="₺"
              required
            />
          </mat-form-field>
        </div>
        <div class="col-lg-12 col-md-12 m0">
          <mat-checkbox
            class="example-margin"
            name="detailedPricing"
            [(ngModel)]="detailedPricing"
            >Kanal Bazlı Fiyatlandırma</mat-checkbox
          >
        </div>
      </fieldset>
      <div *ngIf="detailedPricing && prices.length" class="mt1 row">
        <div *ngFor="let platform of platforms; let i = index">
          <app-divider class="col-lg-12" [content]="platform.name + ' Fiyatları'"></app-divider>
          <div class="col-lg-6 col-md-12 mt1">
            <mat-form-field class="w100">
              <mat-label>Piyasa(Liste) Fiyatı</mat-label>
              <input
                matInput
                [name]="prices[i].pazarYeriID + 'listPrice'"
                [(ngModel)]="prices[i].listPrice"
                #ctrl="ngModel"
                mask="0*.00"
                prefix="₺"
                required
              />
            </mat-form-field>
          </div>
          <div class="col-lg-6 col-md-12 mt1">
            <mat-form-field class="w100">
              <mat-label>Satış Fiyatı</mat-label>
              <input
                matInput
                [name]="prices[i].pazarYeriID + 'price'"
                [(ngModel)]="prices[i].price"
                #ctrl="ngModel"
                mask="0*.00"
                prefix="₺"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</form>
