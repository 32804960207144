import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { ErpSettingsDto } from '../models/erp-settings';

@Injectable({
  providedIn: 'root',
})
export class ErpSettingsService {
  constructor(private req: RequestService) {}

  async getAll(): Promise<ErpSettingsDto[]> {
    return await this.req.get('/Erp/GetAll').then((res) => res.Data);
  }

  async generatenewapikey(): Promise<ErpSettingsDto> {
    return await this.req.get('/Erp/generatenewapikey').then((res) => res.Data);
  }
}
