import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { LoginDTO } from '../models/login';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public isLoggedIn: EventEmitter<boolean> = new EventEmitter();
  private _isLoggedIn = false;
  private _token = '';

  constructor(private router: Router) {
    const localToken = localStorage.getItem('token');
    if (localToken) {
      this._token = localToken;
      this._isLoggedIn = true;
      this.isLoggedIn.emit(this._isLoggedIn);
    } else {
      if (this.router.url != 'login') {
        this.logout();
      }
    }
  }

  checkIsLoggedIn() {
    return this._isLoggedIn;
  }

  getToken() {
    return this._token;
  }

  async login(data: LoginDTO): Promise<any> {
    const response = await fetch(`${environment.auth}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(data),
    })
      .then((x) => x.json())
      .then((res) => {
        if (res.accessToken) {
          this._token = res.accessToken;
          localStorage.setItem('token', this._token);
          this.isLoggedIn.emit(true);
          return { result: true, message: 'Giris Basariyla Tamamlandi' };
        } else {
          return { result: false, message: res.message };
        }
      });
    return response;
  }

  logout() {
    localStorage.removeItem('token');
    setTimeout(() => {
      this._isLoggedIn = false;
      this.isLoggedIn.emit(this._isLoggedIn);
      this.router.navigateByUrl('login');
    }, 30);
  }
}
