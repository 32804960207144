import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from 'src/app/controllers/brand.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { NotificationService } from 'src/app/helpers/notification.service';
import { BrandDto } from 'src/app/models/brand';

export interface DialogData {
  filter: string;
}

@Component({
  selector: 'app-brands',
  templateUrl: './brands.component.html',
  styleUrls: ['./brands.component.scss'],
})
export class BrandsComponent implements OnInit {
  selection = new SelectionModel<BrandDto>(true, []);

  filter = {};

  displayedColumns: string[] = [
    'select',
    'name',
    'action',
  ];

  dataSource = Array<BrandDto>();

  constructor(
    private router: Router,
    private controller: BrandService,
    private notification: NotificationService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.controller.getAllBrands().then((res) => {
      this.dataSource = res;
      this.loadingService.hideSpinner();
    });
  }

  delete(id:string){
    this.loadingService.showSpinner();
    this.controller.deleteBrand(id).then((res) => {
      this.controller.getAllBrands().then((res) => {
        this.dataSource = res;
        this.loadingService.hideSpinner();
        this.notification.show('Marka başarıyla silindi');
      });
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: BrandDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  newButtonClicked(data: any): void {
    if (data) {
      this.router.navigate([`/brand/${data}`]);
    } else {
      this.router.navigateByUrl('/brand');
    }
  }
}
