import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  api = '';

  constructor(private loginservice: LoginService) {
    this.api = environment.api;
  }

  async get(url: string, data?: any): Promise<any> {
    let requestUrl = url;
    if (data) {
      requestUrl += this.urlEncoder(data);
    }

    const response = await fetch(this.api + requestUrl, {
      headers: { Authorization: `Bearer ${this.loginservice.getToken()}` },
    })
      .then((x) => x.json())
      .then((res) => {
        if (res.HasError) {
          if (res.ErrorMessage === '401') {
            this.loginservice.logout();
          }
        }
        return res;
      });
    return response;
  }

  async post(url: string, data: any): Promise<any> {
    const response = await fetch(this.api + url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.loginservice.getToken()}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((x) => x.json())
      .then((res) => {
        if (res.HasError) {
          if (res.ErrorMessage === '401') {
            this.loginservice.logout();
          }
        }
        return res;
      });
    return response;
  }

  async delete(requestUrl: string, id: string) {
    const response = await fetch(this.api + requestUrl + '/' + id, {
      method: 'DELETE',
      headers: { Authorization: `Bearer ${this.loginservice.getToken()}` },
    })
      .then((x) => x.json())
      .then((res) => {
        if (res.HasError) {
          if (res.ErrorMessage === '401') {
            this.loginservice.logout();
          }
        }
        return res;
      });
    return response;
  }

  urlEncoder(data: any) {
    let tempData = '?';
    Object.keys(data).forEach((key) => {
      tempData += key + '=' + data[key] + '&';
    });
    tempData = tempData.slice(0, -1);
    return tempData;
  }
}
