<h1 mat-dialog-title>Ürün gönderim durumu</h1>
<div mat-dialog-content *ngIf="data.pazaryeri">

  <ul>
    <li *ngFor="let item of data.pazaryeri">
      {{item.pazaryeri}}

      <ul>
        <li *ngFor="let msg of item.message">
          {{msg}}
        </li>
      </ul>
    </li>
  </ul>

</div>
<div mat-dialog-content *ngIf="data.pazaryeri.length == 0">
  Ürün gönderim durumu bilinmiyor veya henüz gönderilmemiş !
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>Tamam</button>
</div>
