import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/controllers/dashboard.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { MessagingService } from 'src/app/helpers/messaging.service';
import { DashboardDto } from 'src/app/models/dashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  data: DashboardDto = new DashboardDto();
  chartData = [];

  constructor(private controller: DashboardService, private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.controller.getDashboard().then((res) => {
      this.data = res;
      this.chartData = res.monthlySalesStatistics.map((x: any) => {
        return {
          name: x.orderDate,
          value: x.orderCount,
        };
      });

      this.loadingService.hideSpinner();
    });
  }
}
