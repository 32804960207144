<app-title title="Ürünler" subtext="Tüm ürünlerinizi bu ekran üzerinden yönetebilirsiniz." icon="orders" [button]="true"
  buttonText="Yeni Ürün Ekle" (onButtonClicked)="newButtonClicked(null)"></app-title>

<mat-card class="mb0 p2 pt1">
  <form (change)="getAllProducts()">
    <mat-form-field>
      <mat-label>Ürün Adı</mat-label>
      <input matInput name="name" [(ngModel)]="filter.name" placeholder="Ürün Adına göre filtreyin" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Platform</mat-label>
      <mat-select name="platforms" [(ngModel)]="filter.platforms" multiple>
        <mat-option *ngFor="let platform of platforms" [value]="platform.value">
          {{ platform.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Kategori</mat-label>
      <mat-select name="CategoryID" [(ngModel)]="filter.categoryID">
        <mat-option value="00000000-0000-0000-0000-000000000000">Kategori Seçin</mat-option>
        <mat-option *ngFor="let category of categories" [value]="category.value">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Marka</mat-label>
      <mat-select name="brandID" [(ngModel)]="filter.brandID">
        <mat-option value="00000000-0000-0000-0000-000000000000">Marka Seçin</mat-option>
        <mat-option *ngFor="let brand of brands" [value]="brand.value">
          {{ brand.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Stok Durumu</mat-label>
      <mat-select name="stokDurumu" [(ngModel)]="filter.stokDurumu">
        <mat-option [value]="0">Stok Durumu Seçin</mat-option>
        <mat-option [value]="2">Stokta var</mat-option>
        <mat-option [value]="1">Stokta yok</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Ürün Durumu</mat-label>
      <mat-select name="urunDurumu" [(ngModel)]="filter.urunDurumu">
        <mat-option [value]="0">Ürün Durumu Seçin</mat-option>
        <mat-option [value]="2">Satışa açık</mat-option>
        <mat-option [value]="1">Satışa kapalı</mat-option>
      </mat-select>
    </mat-form-field>

    <button class="big-button" type="button" mat-raised-button color="accent" (click)="getAllProducts()">
      Ürünleri Getir
    </button>
  </form>

  <app-divider content="Sonuçlar"></app-divider>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="code">
      <th mat-header-cell *matHeaderCellDef>Stok Kodu</th>
      <td mat-cell *matCellDef="let element">{{ element.code }}</td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Ürün Adı</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Fiyatı</th>
      <td mat-cell *matCellDef="let element">{{ element.price | number : '1.2-2' }}</td>
    </ng-container>

    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef>Platform</th>
      <td mat-cell *matCellDef="let element">{{ element.platforms }}</td>
    </ng-container>

    <ng-container matColumnDef="salesStatus">
      <th mat-header-cell *matHeaderCellDef>Satış Durumu</th>
      <td mat-cell *matCellDef="let element">
        {{ element.salesStatus ? "Satışa Açık" : "Satışa Kapalı" }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button color="warn" (click)="delete(element.recNo)">
          Sil
        </button>
        <button mat-button color="primary" (click)="newButtonClicked(element.recNo)">
          Düzenle
        </button>
        <button mat-button color="info" (click)="productStatusClicked(element.recNo)">
          Durum
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="8">Hiç ürün bulunamadı</td>
    </tr>
  </table>

  <div class="export-button-holder" *ngIf="selection.selected.length">
    <button type="button" mat-stroked-button [matMenuTriggerFor]="selectedList" color="primary" class="export-button">
      Seçilenleri Gönder
    </button>

    <mat-menu #selectedList="matMenu">
      <button mat-menu-item *ngFor="let platform of platforms" (click)="sendSelectedProducts(platform)">
        {{ platform.name }}
      </button>
    </mat-menu>
  </div>

  <mat-paginator *ngIf="dataSource.length" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
</mat-card>
