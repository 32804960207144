import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-menu',
  templateUrl: './toggle-menu.component.html',
  styleUrls: ['./toggle-menu.component.scss'],
})
export class ToggleMenuComponent implements OnInit {
  @Output() onClick = new EventEmitter<boolean>();
  @Input() isShow: boolean = false;

  constructor() {}

  ngOnInit(): void {}

  _click = () => {
    this.onClick.emit(true);
  };
}
