<div class="row">
  <div class="col-lg-12">
    <h1 class="mb0">Yönetim Paneli</h1>
    <h3>Finuans Pazar'a tekrardan hoşgeldin...</h3>
  </div>
  <div class="col-lg-3">
    <mat-card [routerLink]="['/orders']">
      <span class="title">Günlük Sipariş</span>
      <span class="value">{{ data.dailyOrderCount }}</span>
      <button mat-button color="primary" class="w100">Tümünü Görüntüle</button>
    </mat-card>
  </div>
  <div class="col-lg-3">
    <mat-card [routerLink]="['/orders']">
      <span class="title">Günlük Kazanç</span>
      <span class="value">{{ data.dailyTotalTurnover }}</span>
      <button mat-button color="primary" class="w100">Tümünü Görüntüle</button>
    </mat-card>
  </div>
  <div class="col-lg-3">
    <mat-card [routerLink]="['/orders', { date: 'lastmonth' }]">
      <span class="title">Aylık Sipariş </span>
      <span class="value">{{ data.monthlyOrderCount }}</span>
      <button mat-button color="primary" class="w100">Tümünü Görüntüle</button>
    </mat-card>
  </div>
  <div class="col-lg-3" [routerLink]="['/orders', { date: 'lastmonth' }]">
    <mat-card>
      <span class="title">Aylık Kazanç</span>
      <span class="value">{{ data.monthlyTotalTurnover }}</span>
      <button mat-button color="primary" class="w100">Tümünü Görüntüle</button>
    </mat-card>
  </div>
  <div class="col-lg-12 mt1">
    <div class="row" style="display: flex;">
      <div class="col-lg-3" [routerLink]="['/orders']" style="flex: 1 1;">
        <mat-card class="h100">
          <span class="title">Platform Kazancları</span>
          <ul class="list">
            <li *ngFor="let item of data.marketplaceSalesDistributions">
              <span>{{ item.marketplaceName }}</span
              ><span class="value">{{ item.totalTurnover }}</span>
            </li>
          </ul>
        </mat-card>
      </div>
      <div class="col-lg-3" [routerLink]="['/orders']" style="flex: 1 1;">
        <mat-card class="h100">
          <span class="title">En Çok Satan 10 Ürün</span>
          <ul class="list" *ngIf="data.top10SellingProducts.length">
            <li *ngFor="let item of data.top10SellingProducts">
              {{ item.productName }} - {{ item.salesTotal }}
            </li>
          </ul>
          <img
            src="../../../assets/images/norecord.png"
            alt="norecord"
            *ngIf="!data.top10SellingProducts.length"
            style="width: 100%"
          />
        </mat-card>
      </div>
      <div class="col-lg-6" style="max-height: 40vh">
        <mat-card class="h100">
          <span class="title">Aylık Satış Grafiği</span>

          <div *ngIf="chartData.length">
            <ngx-charts-bar-vertical [results]="chartData">
            </ngx-charts-bar-vertical>

            <button mat-button color="primary" class="w100">
              Tümünü Görüntüle
            </button>
          </div>

          <img
            src="../../../assets/images/notfound.png"
            alt="norecord"
            *ngIf="!chartData.length"
            style="display: block; width: 50%; margin: auto"
          />
        </mat-card>
      </div>
    </div>
  </div>
</div>
