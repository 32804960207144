<mat-drawer-container class="container" autosize *ngIf='isLoggedIn'>
  <mat-drawer #drawer class="sidenav" mode="side" opened="true">
    <div class="top-area">
      <app-logo></app-logo>
      <app-toggle-menu [isShow]="drawer.opened" (click)="menuToggle(drawer)" class="only-mobile"></app-toggle-menu>
    </div>
    <mat-nav-list>
      <a mat-list-item routerLink="dashboard" routerLinkActive="active-link"><app-icon icon="dashboard-layout"></app-icon>Yönetim Paneli</a>
      <a mat-list-item routerLink="orders" routerLinkActive="active-link"><app-icon icon="shopaholic"></app-icon>Siparişler</a>
      <a mat-list-item routerLink="customers" routerLinkActive="active-link"><app-icon icon="customer-insight"></app-icon>Musteriler</a>
      <!-- <a mat-list-item routerLink="invoices" routerLinkActive="active-link"><app-icon icon="purchase-order"></app-icon>Faturalar</a> -->
      <a mat-list-item routerLink="products" routerLinkActive="active-link"><app-icon icon="exclusive-product"></app-icon>Ürünler</a>
      <!-- <a mat-list-item routerLink="settings" routerLinkActive="active-link"><app-icon icon="job"></app-icon>Ayarlar</a> -->
    </mat-nav-list>
    <div class="mb2 sub-menu">
      <div class="p2 pb0">
        <app-divider content="Ayarlar"></app-divider>
      </div>
      <mat-nav-list>
        <!-- <a mat-list-item routerLink="settings" routerLinkActive="active-link"><app-icon icon="job"></app-icon>Genel Ayarlar</a> -->
        <a mat-list-item routerLink="categories" routerLinkActive="active-link"><app-icon icon="category"></app-icon>Ürün Kategorileri</a>
        <a mat-list-item routerLink="brands" routerLinkActive="active-link"><app-icon icon="logo"></app-icon>Ürün Markaları</a>
        <!-- <a mat-list-item routerLink="variantgroups" routerLinkActive="active-link"><app-icon icon="option"></app-icon>Ürün Seçenekleri</a> -->
        <a mat-list-item routerLink="erpsettings" routerLinkActive="active-link"><app-icon icon="shopaholic"></app-icon>ERP Ayarları</a>
        <a mat-list-item routerLink="storesettings" routerLinkActive="active-link"><app-icon icon="online-store"></app-icon>Mağaza Ayarları</a>
        <!-- <a mat-list-item routerLink="ecommercesettings" routerLinkActive="active-link"><app-icon icon="e-commerce"></app-icon>E-ticaret Ayarları</a> -->
        <!-- <a mat-list-item routerLink="settings" routerLinkActive="active-link"><app-icon icon="job"></app-icon>Ayarlar</a> -->
      </mat-nav-list>
    </div>
  </mat-drawer>
  <div class="content" [ngClass]="{'loading': loadingState}">
    <app-toggle-menu [isShow]="!drawer.opened" (click)="menuToggle(drawer)" class="only-mobile"></app-toggle-menu>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>

<div *ngIf='!isLoggedIn'>
  <app-login></app-login>
</div>
