import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "./dialog-data";

@Component({
  selector: 'finuansdialog',
  templateUrl: 'finuansdialog.component.html',
})
export class FinuansDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
