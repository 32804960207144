import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _LoadingState = new BehaviorSubject<boolean>(false);

  constructor() {}

  returnAsObservable() {
    return this._LoadingState.asObservable();
  }

  showSpinner() {
    this._LoadingState.next(true);
  }

  hideSpinner() {
    this._LoadingState.next(false);
  }
}
