import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService } from './helpers/loading.service';
import { LoginService } from './helpers/login.service';
import { MessagingService } from './helpers/messaging.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'project';
  showFiller = true;
  isLoggedIn = false;
  loadingState = false;

  constructor(
    private router: Router,
    private login: LoginService,
    private loadingService: LoadingService,
    private messagingService: MessagingService
  ) {
    this.isLoggedIn = this.login.checkIsLoggedIn();

    this.login.isLoggedIn.subscribe((isLoggedIn: any) => {
      this.isLoggedIn = isLoggedIn;
    });

  }

  ngOnInit() {
    this.loadingService.returnAsObservable().subscribe((x) => {
      this.loadingState = x;
    });

    this.messagingService.receiveMessage();
  }

  menuToggle = (element: any) => {
    element.toggle();
    return false;
  };
}
