export class CategoryDto {
  public id: string = '';
  public sonGuncelleyenKullaniciID: number = 0;
  public firmaID: number = 0;
  public olusturanKullaniciID: number = 0;
  public sonGuncellemeTarihi: Date = new Date();
  public kayitTarihi: Date = new Date();
  public name: string | null = '';
  public parentId: string | null = '';
  public parent: string | null = '';
}

export class Attribute {
  attributeID: number = 0;
  valueID: number = 0;
}

export class CategorySaveDto {
  public 'categoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'categoryName': string | null = '';
  public 'parentCategoryGuid': string | null = '';
  public 'n11CategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'n11CategoryName': string = '';
  public 'n11Attributes': Attribute[] = [];
  public 'trendyolCategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'trendyolCategoryName': string = '';
  public 'trendyolAttributes': Attribute[] = [];
  public 'hepsiburadaCategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'hepsiburadaCategoryName': string = '';
  public 'hepsiburadaAttributes': Attribute[] = [];
  public 'gittiGidiyorCategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'gittiGidiyorCategoryName': string = '';
  public 'gittiGidiyorAttributes': Attribute[] = [];

  public 'ciceksepetiCategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'ciceksepetiCategoryName': string = '';
  public 'ciceksepetiAttributes': Attribute[] = [];

  public 'wooCommerceCategoryGuid': string = '00000000-0000-0000-0000-000000000000';
  public 'wooCommerceCategoryName': string = '';
  public 'wooCommerceAttributes': Attribute[] = [];
}
