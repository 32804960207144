export class OrderDto {
  public platform: string = '';
  public totalPrice: string = '';
  public orderNumber: string = '';
  public date: string = '';
}

export class OrderFilter {
  public Platforms: string = '0';
  public CustomerName: string = '';
  public OrderNumber: string = '';
  public OrderStartDate: string = new Date().toISOString().substring(0, 10);
  public OrderLastDate: string = new Date().toISOString().substring(0, 10);
  public MinPrice: number = 0;
  public MaxPrice: number = 0;
}
