<app-title
  title="Kategori Bilgileri"
  subtext="Kategori bilgilerini aşağıdaki formlar yardımıyla girebilirsiniz."
  icon="category"
></app-title>

<form class="row" (submit)="save()">
  <div class="col-lg-8 col-md-12">
    <mat-card class="mb2 p2">
      <app-divider content="Genel Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Ust Kategori</mat-label>
            <mat-select
              name="parentCategoryGuid"
              [(ngModel)]="data.parentCategoryGuid"
              #ctrl="ngModel"
              required
            >
              <mat-option value="00000000-0000-0000-0000-000000000000"
                >Kategori Secilmedi</mat-option
              >
              <mat-option
                *ngFor="let option of selectOptions.parent"
                [value]="option.value"
                s
              >
                {{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Adi</mat-label>
            <input
              matInput
              name="categoryName"
              [(ngModel)]="data.categoryName"
              #ctrl="ngModel"
              required
            />
          </mat-form-field>
        </div>
      </fieldset>

      <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
        <mat-tab
          style="min-width: 800px;"
          *ngFor="let platform of platforms"
          [label]="platform  | titlecase"
        >
          <div class="col-lg-12">
            <h3 class="mt1">{{ platform | titlecase }} Mağaza Kategori Ayarları</h3>

            <fieldset class="row mt1">
              <div class="col-lg-12 col-md-12">
                <mat-form-field class="w100">
                  <mat-label>Kategori</mat-label>

                  <input
                    matInput
                    [placeholder]="platform + ' Kategorisi Seçiniz' | titlecase"
                    [(ngModel)]="searchInputs[platform]"
                    (input)="getSuggestions(platform, searchInputs[platform])"
                    [ngModelOptions]="{ standalone: true }"
                    [matAutocomplete]="auto"
                  />
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                    <mat-option *ngIf="!loading[platform] && data[platform + 'CategoryName']" disabled>
                     {{ data[platform + "CategoryName"] }} (<small>Mevcut Seçim</small>)
                    </mat-option>
                    <mat-option *ngIf="!loading[platform] && !data[platform + 'CategoryName']">
                      Kategori Seçiniz
                    </mat-option>
                    <mat-option *ngIf="loading[platform]">
                      Yükleniyor
                    </mat-option>
                    <mat-option *ngFor="let item of selectOptions[platform]" (click)="setCategory(platform, item)">
                      {{ item.name }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
              </div>
              <div class="col-lg-12 col-md-12 mt1" *ngIf="platformAttributes">
                <div class="row">
                  <div
                    class="col-lg-6 col-md-6"
                    *ngFor="let attribute of platformAttributes[platform]"
                  >
                    <mat-form-field class="w100">
                      <mat-label>{{ attribute.name }}</mat-label>
                      <mat-select
                        [(ngModel)]="
                          selectedPlatformAttributes[platform][attribute.id]
                        "
                        #ctrl="ngModel"
                        [attr.required]="attribute.required ? 'required' : null"
                        [ngModelOptions]="{ standalone: true }"
                        value="None"
                      >
                        <mat-option>Seçenek Seçilmedi</mat-option>
                        <mat-option
                          *ngFor="let option of attribute.attributes"
                          [value]="option.id"
                          (click)='setPlatformAttribute(platform,attribute,option)'
                        >
                          {{ option.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </fieldset>

            <div class="row mt1">
              <div class="col-lg-3 col-lg-offset-6 col-md-6">
                <button
                  mat-raised-button
                  color="warn"
                  class="w100"
                  *ngIf="data.categoryGuid"
                >
                  Kategoriyi Sil
                </button>
              </div>
              <div class="col-lg-3 col-md-6">
                <button
                  mat-raised-button
                  color="accent"
                  class="w100"
                  value="Submit"
                >
                  Kategoriyi Kaydet
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-card>
  </div>
</form>
