import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderService } from 'src/app/controllers/order.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { OrderFilter } from 'src/app/models/order';

export interface OrderTable {
  platform: string;
  customerName: string;
  date: string;
  totalPrice: number;
}

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  selection = new SelectionModel<OrderTable>(true, []);

  sub: any;

  filter: OrderFilter = new OrderFilter();

  displayedColumns: string[] = [
    'select',
    'platform',
    'customerName',
    'date',
    'totalPrice',
    'action',
  ];
  dataSource:any;

  constructor(
    private controller: OrderService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.sub = this.route.params.subscribe((params) => {
      if (params.CustomerName) {
        this.filter.CustomerName = params.CustomerName;
      }
      if (params.date) {
        if (params.date == 'lastmonth') {
          let tempDate = new Date();
          tempDate.setMonth(tempDate.getMonth() - 1);
          this.filter.OrderStartDate = tempDate.toISOString().substring(0, 10);
        }
      }
      this.loadingService.hideSpinner();
      this.getTableData();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getTableData() {
    this.controller.getAll(this.filter).then((res) => {
      this.dataSource = res;});
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row:any) => this.selection.select(row));
  }

  checkboxLabel(row?: OrderTable): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }
}
