<app-title
  title="Siparişler"
  subtext="Tüm siparişlerinizi bu ekran üzerinden yönetebilirsiniz."
  icon="orders"
></app-title>

<mat-card class="mb0 p2 pt1">
  <form>
    <mat-form-field>
      <mat-label>Müşteri Adı Soyadı</mat-label>
      <input matInput name="CustomerName" [(ngModel)]="filter.CustomerName" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sipariş Numarası</mat-label>
      <input matInput name="OrderNumber" [(ngModel)]="filter.OrderNumber" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Baslangic Tarihi</mat-label>
      <input
        type="date"
        matInput
        name="OrderStartDate"
        [(ngModel)]="filter.OrderStartDate"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Bitis Tarihi</mat-label>
      <input
        type="date"
        matInput
        name="OrderLastDate"
        [(ngModel)]="filter.OrderLastDate"
      />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Minimum Fiyat</mat-label>
      <input matInput name="MinPrice" [(ngModel)]="filter.MinPrice" />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Maximum Fiyat</mat-label>
      <input matInput name="MaxPrice" [(ngModel)]="filter.MaxPrice" />
    </mat-form-field>

    <button
      class="big-button"
      type="button"
      mat-raised-button
      color="accent"
      (click)="getTableData()"
    >
      Siparisleri Getir
    </button>
  </form>

  <app-divider content="Sonuçlar"></app-divider>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef>Pazaryeri</th>
      <td mat-cell *matCellDef="let element">{{ element.platform }}</td>
    </ng-container>

    <ng-container matColumnDef="customerName">
      <th mat-header-cell *matHeaderCellDef>Müşteri</th>
      <td mat-cell *matCellDef="let element">{{ element.customerName }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Fatura Tarihi</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'dd/MM/yyyy' }}</td>
    </ng-container>

    <ng-container matColumnDef="totalPrice">
      <th mat-header-cell *matHeaderCellDef>Sipariş Tutarı</th>
      <td mat-cell *matCellDef="let element">{{ element.totalPrice | number : '1.2-2' }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button>Sil</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="6">Hic siparis bulunamadi</td>
    </tr>
  </table>

  <!-- <div class="export-button-holder">
    <button
      type="button"
      mat-stroked-button
      [matMenuTriggerFor]="selectedList"
      color="primary"
      class="export-button"
    >
      Secilileri Onayla
    </button>

    <mat-menu #selectedList="matMenu">
      <button mat-menu-item *ngFor="let platform of platforms" (click)="sendSelectedProducts(platform)">
        {{ platform.name }}
      </button>
    </mat-menu>
  </div> -->

  <mat-paginator
    *ngIf="dataSource.length"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
