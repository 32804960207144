<app-title
  title="Müşteriler"
  subtext="Müşterilerinizi bu ekran üzerinden yönetebilirsiniz."
  [button]="false"
  buttonText="Yeni Müşteri Ekle"
  (onButtonClicked)="newButtonClicked($event)"
></app-title>

<mat-card class="mb0 p2 pt1">
  <app-divider content="Sonuçlar"></app-divider>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Müşteri Adı</th>
      <td mat-cell *matCellDef="let element">{{ element.unvani }}</td>
    </ng-container>

    <ng-container matColumnDef="vergiDaire">
      <th mat-header-cell *matHeaderCellDef>Vergi Dairesi</th>
      <td mat-cell *matCellDef="let element">{{ element.vergiDaire }}</td>
    </ng-container>

    <ng-container matColumnDef="vergiNo">
      <th mat-header-cell *matHeaderCellDef>Vergi No</th>
      <td mat-cell *matCellDef="let element">{{ element.vergiNo }}</td>
    </ng-container>

    <ng-container matColumnDef="adres">
      <th mat-header-cell *matHeaderCellDef>Adres</th>
      <td mat-cell *matCellDef="let element">
        {{ element.sehir }} {{ element.kasaba }} {{ element.mahalle }}
        {{ element.cadde }} {{ element.sokak }} {{ element.bina }}
        {{ element.kapi }}
      </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          mat-button
          color="primary"
          (click)="redirectToOrders(element.unvani)"
        >
          Siparişleri Görüntüle
        </button>
        <button
          mat-button
          color="secondary"
          (click)="newButtonClicked(element.id)"
        >
          Görüntüle
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="5">Hic musteri bulunamadi</td>
    </tr>
  </table>

  <mat-paginator
    *ngIf="dataSource && dataSource.length"
    [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons
  ></mat-paginator>
</mat-card>
