import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CategoryService } from 'src/app/controllers/category.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { NotificationService } from 'src/app/helpers/notification.service';
import { Attribute, CategorySaveDto } from 'src/app/models/category';
import { SelectOption } from 'src/app/models/select';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit {
  data: any = new CategorySaveDto();

  platforms: string[] = ['n11', 'trendyol', 'gittiGidiyor', 'hepsiburada','cicekSepeti','wooCommerce'];

  loading = {
    n11: false,
    gittiGidiyor: false,
    trendyol: false,
    hepsiburada: false,
    cicekSepeti:false,
    wooCommerce:false,
  } as {
    [key: string]: boolean;
  };

  selectOptions = {
    parent: [],
    n11: [],
    trendyol: [],
    gittiGidiyor: [],
    hepsiburada: [],
    cicekSepeti:[],
    wooCommerce:[],
  } as {
    [key: string]: SelectOption[];
  };

  searchInputs = {
    n11: '',
    gittiGidiyor: '',
    trendyol: '',
    hepsiburada: '',
    cicekSepeti:'',
    wooCommerce:'',
  } as {
    [key: string]: string;
  };

  platformAttributes = {
    n11: [],
    gittiGidiyor: [],
    trendyol: [],
    hepsiburada: [],
    cicekSepeti:[],
    wooCommerce:[],
  } as {
    [key: string]: any[];
  };

  selectedPlatformAttributes = {
    n11: {},
    gittiGidiyor: {},
    trendyol: {},
    hepsiburada: {},
    cicekSepeti:{},
    wooCommerce:{}
  } as {
    [key: string]: any;
  };

  private sub: any;

  constructor(
    private controller: CategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private loadingService: LoadingService
  ) { }

   ngOnInit(): void {
    this.loadingService.showSpinner();

    this.getOptions();
    this.sub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.controller.get(params.id).then((res) => {
          this.data = res;
          this.platforms.forEach((platform) => {
            this.searchInputs[platform] = (this.data as any)[
              platform + 'CategoryName'
            ];
            this.getAttributes(
              platform,
              (this.data as any)[platform + 'CategoryGuid'],
              true
            );
            this.data[platform + 'Attributes'].forEach((attribute: any) => {
              this.selectedPlatformAttributes[platform][attribute.attributeID] =
                attribute.valueID + '';
            });
          });
          this.selectOptions.parent = this.selectOptions.parent.filter(
            (x) => x.value !== res.categoryGuid
          );

          this.loadingService.hideSpinner();
        });
      } else
      this.loadingService.hideSpinner();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  getOptions() {
    this.controller.getAllOptions().then((res) => {
      this.selectOptions.parent = res;
    });
    this.platforms.forEach((platform) => {
      this.controller.getOptions(platform, '').then((res) => {
        this.selectOptions[platform] = res;
      });
    });
  }

  getAttributes(platform: string, id: string, isInitial: boolean) {
    this.controller.getAttributes(platform, id).then((res) => {
      if (Array.isArray(res)) {
        if (!isInitial) {
          this.selectedPlatformAttributes[platform] = {};
        }
        this.platformAttributes[platform] = res;
        this.platformAttributes[platform].forEach((attribute) => {
          this.selectedPlatformAttributes[platform][attribute] = '';
        });
      }
    });
  }

  getSuggestions(categoryType: string, searchvalue: string) {
    setTimeout(() => {
      if (searchvalue === this.searchInputs[categoryType]) {
        this.loading[categoryType] = true;
        this.controller
          .getCategorySuggestions(categoryType, searchvalue)
          .then((res) => {
            this.selectOptions[categoryType] = res;
            this.loading[categoryType] = false;
          });
      }
    }, 500)
  }

  setCategory(platform: string, item: SelectOption) {
    (this.data as any)[platform + 'CategoryGuid'] = item.value;
    (this.data as any)[platform + 'CategoryName'] = item.name;
    (this.searchInputs as any)[platform] = item.name;
    this.getAttributes(platform, item.value, false);
  }

  setPlatformAttribute(platform: string, attribute: any, item: any) {
    if (item) {
      this.selectedPlatformAttributes[platform][attribute.id] = item.id;
    } else {
      this.selectedPlatformAttributes[platform][attribute.id] = undefined;
    }
  }

  prepareAttributes() {
    this.platforms.forEach((platform) => {
      this.data[platform + 'Attributes'] = [];
      const _temp = this.selectedPlatformAttributes[platform];
      Object.keys(_temp).forEach((attribute) => {
        if (_temp[attribute]) {
          this.data[platform + 'Attributes'].push({
            attributeID: Number(attribute),
            valueID: Number(_temp[attribute]),
          });
        }
      });
    });
  }

  save() {
    this.prepareAttributes();

    this.controller.save(this.data).then((res) => {
      this.router.navigate(['/categories']);
      if (this.data.categoryGuid) {
        this.notification.show('Kategori başarıyla düzenlendi');
      } else {
        this.notification.show('Kategori başarıyla eklendi');
      }
    });
  }
}
