import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/helpers/login.service';
import { MessagingService } from 'src/app/helpers/messaging.service';
import { RequestService } from 'src/app/helpers/request.service';
import { LoginDTO } from 'src/app/models/login';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginData: LoginDTO = {
    UserName: '',
    Password: '',
    LoginType: environment.logintypes,
  };

  constructor(
    private router: Router,
    private _login: LoginService,
    private _snackBar: MatSnackBar,
    private messagingService: MessagingService,
    private req: RequestService
  ) {}

  ngOnInit(): void {}

  submit() {
    this.router.navigateByUrl('dashboard');
    this._login.login(this.loginData).then((res) => {
      if (res.result) {
        this.req.get('/order/saveallorders').then((res) => {});

        this.messagingService.requestPermission();
        this.router.navigateByUrl('dashboard');
      } else {
        this._snackBar.open('Kullanici bilgileriniz hatali.', '', {
          duration: 2000,
        });
      }
    });
  }
}
