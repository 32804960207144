import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-storesettings',
  templateUrl: './storesettings.component.html',
  styleUrls: ['./storesettings.component.scss'],
})
export class StoresettingsComponent implements OnInit {
  generalState = {
    n11: false,
    gittigidiyor: false,
    hepsiburada: false,
    trendyol: false,
  } as {
    [key: string]: boolean;
  };

  data = {};

  constructor() {}

  ngOnInit(): void {}

  changeState(name: string) {
    if (name != null) {
      this.generalState.n11 = false;
      this.generalState.gittigidiyor = false;
      this.generalState.hepsiburada = false;
      this.generalState.trendyol = false;
      this.generalState[name] = true;
    }
  }
}
