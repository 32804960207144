import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from 'src/app/controllers/customer.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { CustomerDto } from 'src/app/models/customer';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
})
export class CustomerComponent implements OnInit {
  data: CustomerDto = new CustomerDto();
  private sub: any;

  constructor(
    private controller: CustomerService,
    private route: ActivatedRoute,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.sub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.controller.get(params.id).then((res) => {
          this.data = res;
          this.loadingService.hideSpinner();
        });
      }
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
