<app-title
  title="Marka"
  subtext="Urünlerinizin markalarini bu ekran üzerinden yönetebilirsiniz."
  icon="orders"
  [button]="true"
  buttonText="Yeni Marka Ekle"
  (onButtonClicked)="newButtonClicked(null)"
></app-title>

<mat-card class="mb0 p2 pt1">
  <app-divider content="Sonuçlar"></app-divider>

  <table
    *ngIf="dataSource"
    mat-table
    [dataSource]="dataSource"
    class="mat-elevation-z8"
  >
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Marka Adı</th>
      <td mat-cell *matCellDef="let element">{{ element.name }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button
          type="button"
          color="warn"
          mat-button
          (click)="delete(element.id)"
        >
          Sil
        </button>
        <button
          mat-button
          color="primary"
          (click)="newButtonClicked(element.id)"
        >
          Düzenle
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
