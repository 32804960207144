import { Component, Input, Output, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss'],
})
export class TitleComponent implements OnInit {
  @Input() title = '';
  @Input() subtext = '';
  @Input() button = false;
  @Input() buttonText = '';
  @Output() onButtonClicked = new ReplaySubject(0);

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle(
      'Finuans Pazar' + (this.title ? ' - ' + this.title : '')
    );
  }

  _onButtonClicked() {
    this.onButtonClicked.next(true);
  }
}
