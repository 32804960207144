import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { NotificationService } from './notification.service';
import { RequestService } from './request.service';

@Injectable({
  providedIn: 'root',
})
export class MessagingService {
  currentMessage = new BehaviorSubject(null);
  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private req: RequestService,
    private notify:NotificationService
  ) {
    // this.angularFireMessaging.messages.subscribe(
    //   (_messaging) => {
    //     _messaging.onMessage = _messaging.onMessage.bind(_messaging);
    //     _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    //   }
    // )
  }

  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log(token);
        this.req
          .post('/store/savecustomerfcm', { token: token })
          .then((res) => {
            if (res.Data) {
            }
          });
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    this.angularFireMessaging.messages.subscribe((payload:any) => {
      this.notify.show(payload.notification.title + "\n" + payload.notification.body);
      console.log('new message received. ', payload);
      //this.currentMessage.next(payload);
    });
  }
}
