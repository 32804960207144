import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BrandService } from 'src/app/controllers/brand.service';
import { CategoryService } from 'src/app/controllers/category.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { NotificationService } from 'src/app/helpers/notification.service';
import { SelectOption, SelectOptionNumber } from 'src/app/models/select';
import { ProductService } from '../../controllers/product.service';
import { ProductDto, ProductFilter } from '../../models/product';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FinuansDialog } from '../dialog/finuansdialog.component';
import { PazaryeriStatus } from '../dialog/dialog-data';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
  selection = new SelectionModel<ProductDto>(true, []);

  filter: ProductFilter = new ProductFilter();

  displayedColumns: string[] = [
    'select',
    'code',
    'name',
    'price',
    'platform',
    'salesStatus',
    'action',
  ];

  dataSource = Array<ProductDto>();

  categories: SelectOption[] = [];
  brands: SelectOption[] = [];
  platforms: SelectOptionNumber[] = [];
  productStatus: any[] = [];

  constructor(
    private router: Router,
    private controller: ProductService,
    private categoryController: CategoryService,
    private brandController: BrandService,
    private notification: NotificationService,
    private loadingService: LoadingService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.getAllProducts();
    this.getOptions();
  }

  checkIsloaded() {
    if (this.categories && this.brands && this.platforms && this.dataSource) {
      this.loadingService.hideSpinner();
    }
  }

  delete(id: string) {
    this.controller.deleteProduct(id).then((res) => {
      this.getAllProducts();
      this.notification.show('Ürün başarıyla silindi');
    });
  }

  getOptions() {
    this.categoryController.getAllOptions().then((res) => {
      this.categories = res;
      this.checkIsloaded();
    });
    this.brandController.getBrandSelectbox().then((res) => {
      this.brands = res;
      this.checkIsloaded();
    });
    this.controller.getAllPlatforms().then((res) => {
      this.platforms = res;
      this.checkIsloaded();
    });
  }

  getAllProducts() {
    this.controller.getAllProducts(this.filter).then((res) => {
      this.dataSource = res;
      this.checkIsloaded();
    });
  }

  sendSelectedProducts(platform: SelectOptionNumber) {
    const reqData = this.selection.selected.map((x) => {
      return x.recNo + '';
    });
    this.controller.sendProduct(platform.url, reqData).then((res) => {
      this.notification.show('Seçili ürünler gönderim kuyruğuna alındı.');
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: ProductDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  productStatusClicked(id: any) {
    this.controller.getProductLog(id).then((res: any) => {
      let list: PazaryeriStatus[] = [];

      res.logs.forEach((x: any) => {
        let item: PazaryeriStatus = {
          message: x.message,
          pazaryeri: x.pazaryeriName,
        };

        list.push(item);
      });

      this.dialog.open(FinuansDialog, {
        data: {
          pazaryeri: list,
        },
      });
    });
  }

  newButtonClicked(id: any): void {
    debugger;
    if (id) {
      this.router.navigate([`/product/${id}`]);
    } else {
      this.router.navigateByUrl('/product');
    }
  }
}
