import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoryService } from 'src/app/controllers/category.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { CategoryDto } from 'src/app/models/category';
import { SelectOption } from 'src/app/models/select';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  selection = new SelectionModel<CategoryDto>(true, []);

  displayedColumns: string[] = ['select', 'name', 'parent', 'action'];

  dataSource = Array<CategoryDto>();

  parentCategories: SelectOption[] = [];

  constructor(private router: Router, private controller: CategoryService, private loadingService: LoadingService) {
    this.loadingService.showSpinner();
    this.controller.getOptions('parent').then((parents) => {
      this.parentCategories = parents;

      this.controller.getAll().then((res) => {
        this.dataSource = res;
        this.dataSource.forEach((item) => {
          const tempParent = this.parentCategories.filter((x) => {
            return x.value === item.parentId;
          })[0];
          if (tempParent) {
            item.parent = tempParent.name;
          } else {
            item.parent = 'Üst Kategori Seçilmedi';
          }
        });

        this.loadingService.hideSpinner();
      });
    });
   }

  ngOnInit(): void {

  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: CategoryDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  newButtonClicked(id: any): void {
    if (id) {
      this.router.navigate([`/category/${id}`]);
    } else {
      this.router.navigate(['/category']);
    }
  }
}
