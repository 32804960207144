export class MonthlyOrderDto {
  public orderDate: string = '';
  public orderCount: string = '';
}

export class MarketplaceSalesDto {
  public marketplaceName: string = '';
  public totalTurnover: string = '';
}

export class ProductSaleDto {
  public productName: string = '';
  public salesTotal: string = '';
}

export class DashboardDto {
  public dailyOrderCount: string = '';
  public dailyTotalTurnover: string = '';
  public monthlyOrderCount: string = '';
  public monthlyTotalTurnover: string = '';
  public monthlySalesStatistics: MonthlyOrderDto[] = [];
  public marketplaceSalesDistributions: MarketplaceSalesDto[] = [];
  public top10SellingProducts: ProductSaleDto[] = [];
}
