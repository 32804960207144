import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { ProductDto, ProductFilter, ProductSaveDto } from '../models/product';
import { SelectOptionNumber } from '../models/select';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private req: RequestService) {}

  async getAllProducts(filter: ProductFilter | null): Promise<ProductDto[]> {
    let filterData = null;
    if (filter) {
      filterData = filter as any;
      filterData.stokDurumu = Number(filter.stokDurumu);
      filterData.urunDurumu = Number(filter.urunDurumu);
    }
    return await this.req
      .post('/Product/productslist', filterData)
      .then((res) => res.Data);
  }

  async getProduct(id: string): Promise<ProductSaveDto> {
    return await this.req.get(`/Product/getproduct?id=${id}`).then((res) => res.Data);
  }

  async getProductLog(id: string): Promise<string[]> {
    return await this.req.get(`/Product/getproductlog?id=${id}`).then((res) => res.Data);
  }

  async saveProduct(data: ProductSaveDto): Promise<ProductDto> {
    return await this.req.post('/Product/saveproduct', data);
  }

  async sendProduct(platformName: string, data: string[]) {
    return await this.req
      .post(`/Product/send${platformName}product`, data)
      .then((res) => {
      });
  }

  async getAllPlatforms(): Promise<SelectOptionNumber[]> {
    return await this.req.get('/Product/platforms').then((res) => {
      return res.Data.map((x: any) => new SelectOptionNumber(x.name, x.id,x.url));
    });
  }

  async deleteProduct(id: string): Promise<boolean> {
    return await this.req.delete('/Product', id);
  }
}
