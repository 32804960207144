import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from 'src/app/controllers/brand.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { NotificationService } from 'src/app/helpers/notification.service';
import { BrandDto } from 'src/app/models/brand';

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit {
  data: BrandDto = new BrandDto();

  sub: any;

  constructor(
    private route: ActivatedRoute,
    private controller: BrandService,
    private router: Router,
    private notification: NotificationService,
    private loadingService: LoadingService
  ) {}

  ngOnInit(): void {
    this.loadingService.showSpinner();
    this.sub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.controller.getBrand(params.id).then((res) => {
          this.data = res;
          this.loadingService.hideSpinner();
        });
      } else {
        this.loadingService.hideSpinner();
      }
    });
  }

  save() {
    this.loadingService.showSpinner();
    this.controller.saveBrand(this.data).then((res) => {
      this.router.navigate(['/brands']);
      if (this.data.id) {
        this.notification.show('Marka başarıyla düzenlendi');
      } else {
        this.notification.show('Marka başarıyla eklendi');
      }

      this.loadingService.hideSpinner();
    });
  }

  delete() {
    this.loadingService.showSpinner();
    this.controller.deleteBrand(this.data.id).then((res) => {
      this.router.navigate(['/brands']);
      this.notification.show('Marka başarıyla silindi');
      this.loadingService.hideSpinner();
    });
  }
}
