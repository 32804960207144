export class ProductFilter {
  platforms: number[] = [2, 3, 4, 5];
  name: string = '';
  stokDurumu?: number = 0;
  urunDurumu?: number = 0;
  categoryID: string = '00000000-0000-0000-0000-000000000000';
  brandID: string = '00000000-0000-0000-0000-000000000000';
}

export class ProductDto {
  public id: string = '00000000-0000-0000-0000-000000000000';
  public code?: string = '';
  public name?: string = '';
  public slug?: string = '';
  public currencyType?: string = '';
  public brandId: string = '00000000-0000-0000-0000-000000000000';
  public barcode?: string = '';
  public categoryId: string = '00000000-0000-0000-0000-000000000000';
  public salesStatus: boolean = true;
  public amount: number = 0;
  public description?: string = '';
  public kdv: number = 18;
  public purchasePrice: number = 0;
  public price: number = 0;
  public listPrice: number = 0;
  public invoiceTitle?: string = '';
  public stockReductionAmount: number = 0;
  public productMaximumPurchase: number = 0;
  public desi: number = 0;
  public width: number = 0;
  public height: number = 0;
  public size: number = 0;
  public weight: number = 0;
  public recNo?: string = '';
}

export class ProductImageDto {
  index: number = 0;
  base64Image?: string = undefined;
  imageType:string='';
}

export class ProductPriceDto {
  pazarYeriID: number = 0;
  price: number = 0;
  listPrice: number = 0;
}

export class ProductSaveDto {
  public product: ProductDto = new ProductDto();
  public images?: ProductImageDto[] = [];
  public prices: ProductPriceDto[] = [];
}
