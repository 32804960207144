import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { CategoryDto, CategorySaveDto } from '../models/category';
import { SelectOption } from '../models/select';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private req: RequestService) {}

  async getAll(): Promise<CategoryDto[]> {
    return await this.req.get('/Category/GetAll').then((res) => res.Data);
  }

  async get(id: string): Promise<CategorySaveDto> {
    return await this.req
      .get(`/Category/getcategory?id=${id}`)
      .then((res) => res.Data);
  }

  async save(data: CategorySaveDto): Promise<CategorySaveDto> {
    return await this.req.post('/Category/savecategory', data);
  }

  async getOptions(type: string, search?: string): Promise<SelectOption[]> {
    let url = `/Category/get${type}categories`;
    if (search) {
      url += `?name=${search}`;
    }
    return await this.req.get(url).then((res) =>
      res.Data.map((category: CategoryDto) => {
        const tempOption: SelectOption = {
          name: category.name || '',
          value: category.id,
        };
        return tempOption;
      })
    );
  }

  async getCategorySuggestions(categoryType: string, searchvalue: string) {
    return await this.req
      .get(`/Category/get${categoryType}categories?name=${searchvalue}`)
      .then((res) => {
        return res.Data.map((category: any) => {
          const tempOption: SelectOption = {
            name: category.categoryName || '',
            value: category.id,
          };
          return tempOption;
        })
      });
  }

  async getAllOptions(): Promise<SelectOption[]> {
    return await this.req.get('/Category/GetAll').then((res) => {
      const selectList: SelectOption[] = res.Data.map((x: any) => {
        const tempOption = new SelectOption();
        tempOption.name = x.name;
        tempOption.value = x.id;
        return tempOption;
      });
      return selectList;
    });
  }

  async getAttributes(platform:string, categoryId:string): Promise<any> {
    return await this.req.get(`/Category/get${platform}attributes?id=${categoryId}`)
    .then((res) => res.Data);
  }
}
