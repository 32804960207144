import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// 3th party components
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import {MatDialogModule} from '@angular/material/dialog';

// components
import { DividerComponent } from './shared/divider/divider.component';
import { IconComponent } from './shared/icon/icon.component';
import { LogoComponent } from './shared/logo/logo.component';
import { TitleComponent } from './shared/title/title.component';
import { ToggleMenuComponent } from './shared/toggle-menu/toggle-menu.component';

// pages
import { BrandComponent } from './pages/brand/brand.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryComponent } from './pages/category/category.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ErpsettingsComponent } from './pages/erpsettings/erpsettings.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { LoginComponent } from './pages/login/login.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductsComponent } from './pages/products/products.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { GittigidiyorsettingsComponent } from './pages/storesettings/gittigidiyorsettings/gittigidiyorsettings.component';
import { HepsiburadasettingsComponent } from './pages/storesettings/hepsiburadasettings/hepsiburadasettings.component';
import { N11settingsComponent } from './pages/storesettings/n11settings/n11settings.component';
import { StoresettingsComponent } from './pages/storesettings/storesettings.component';
import { TrendyolsettingsComponent } from './pages/storesettings/trendyolsettings/trendyolsettings.component';
import { VariantgroupsComponent } from './pages/variantgroups/variantgroups.component';

import localeTr from '@angular/common/locales/tr';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'src/environments/environment';

import { QuillModule } from 'ngx-quill'
import { CicekSepetisettingsComponent } from './pages/storesettings/ciceksepeti/ciceksepetisettings.component';
import { WoCommercesettingsComponent } from './pages/storesettings/wocommerce/wocommercesettings.component';
import { FinuansDialog } from './pages/dialog/finuansdialog.component';

registerLocaleData(localeTr, 'tr');

@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    OrdersComponent,
    InvoicesComponent,
    ProductsComponent,
    SettingsComponent,
    ToggleMenuComponent,
    TitleComponent,
    ProductComponent,
    IconComponent,
    DividerComponent,
    CategoriesComponent,
    BrandsComponent,
    VariantgroupsComponent,
    ErpsettingsComponent,
    StoresettingsComponent,
    LogoComponent,
    CustomersComponent,
    CategoryComponent,
    BrandComponent,
    GittigidiyorsettingsComponent,
    N11settingsComponent,
    HepsiburadasettingsComponent,
    TrendyolsettingsComponent,
    CustomerComponent,
    CicekSepetisettingsComponent,
    WoCommercesettingsComponent,
    FinuansDialog
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSelectModule,
    MatSliderModule,
    MatSidenavModule,
    MatListModule,
    MatTabsModule,
    MatCardModule,
    MatInputModule,
    MatMenuModule,
    MatExpansionModule,
    MatTableModule,
    MatButtonModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    QuillModule.forRoot(),
    NgxChartsModule,
    NgxMaskModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    MatDialogModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'outline' },
    },
    {
      provide: LOCALE_ID, useValue: "tr-TR"
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
