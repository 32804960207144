<app-title
  title="Faturalar"
  subtext="Tüm faturalarınızı bu ekran üzerinden yönetebilirsiniz."
  icon="orders"
></app-title>

<mat-card class="mb0 p2 pt1">
  <form>
    <mat-form-field>
      <mat-label>Platform</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Müşteri Adı Soyadı</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Sipariş Numarası</mat-label>
      <input matInput />
    </mat-form-field>
    <mat-form-field>
      <mat-label>Kargolanma Durumu</mat-label>
      <input matInput />
    </mat-form-field>
  </form>

  <app-divider content="Sonuçlar"></app-divider>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
          [aria-label]="checkboxLabel()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(row) : null"
          [checked]="selection.isSelected(row)"
          [aria-label]="checkboxLabel(row)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="platform">
      <th mat-header-cell *matHeaderCellDef>Pazaryeri</th>
      <td mat-cell *matCellDef="let element">{{ element.platform }}</td>
    </ng-container>

    <ng-container matColumnDef="customer">
      <th mat-header-cell *matHeaderCellDef>Müşteri</th>
      <td mat-cell *matCellDef="let element">{{ element.customer }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Fatura Tarihi</th>
      <td mat-cell *matCellDef="let element">{{ element.date }}</td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef>Sipariş Tutarı</th>
      <td mat-cell *matCellDef="let element">{{ element.price }}</td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-button>Sil</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
