export class SelectOption {
  public name: string = '';
  public value: string = '';
  constructor(name?: any, value?: any) {
    this.name = name || '';
    this.value = value || '';
  }
}

export class SelectOptionNumber {
  public name: string = '';
  public value?: number = 0;
  public url: string = '';
  constructor(name?: any, value?: any, url?: string) {
    this.name = name || '';
    this.value = value || null;
    this.url = url || '';
  }
}
