<app-title
  title="Erp Ayarları "
  subtext="Erp Ayarlarinizi buradan guncelleyebilirsiniz."
  icon="erpsettings"
></app-title>

<div class="row">
  <div class="col-lg-4">
    <mat-card class="p2">
      <form>
        <form>
          <fieldset class="row mt1">
            <div class="col-lg-12 col-md-12 m0">
              <mat-form-field class="w100">
                <mat-label>api key</mat-label>
                <input
                  matInput
                  name="apiKey"
                  [(ngModel)]="data.apiKey"
                  #ctrl="ngModel"
                />
              </mat-form-field>
            </div>
            <div class="col-lg-12 col-md-12 m0">
              <mat-form-field class="w100">
                <mat-label>api secret</mat-label>
                <input
                  matInput
                  name="apiSecret"
                  [(ngModel)]="data.apiSecret"
                  #ctrl="ngModel"
                />
              </mat-form-field>
            </div>
          </fieldset>

          <app-divider></app-divider>

          <div class="row mt1">
            <div class="col-lg-12 col-md-12">
              <button
                type="button"
                mat-raised-button
                color="primary"
                class="w100"
                (click)="reGenerate()"
              >
                Degerleri Yenile
              </button>
            </div>
          </div>
        </form>
      </form>
    </mat-card>
  </div>
</div>
