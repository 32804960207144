import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/helpers/notification.service';
import { RequestService } from 'src/app/helpers/request.service';

@Component({
  selector: 'app-hepsiburadasettings',
  templateUrl: './hepsiburadasettings.component.html',
  styleUrls: ['./hepsiburadasettings.component.scss'],
})
export class HepsiburadasettingsComponent implements OnInit {
  data: HepsiburadaSettings = {
    id: '00000000-0000-0000-0000-000000000000',
    name: '',
    userName: '',
    password: '',
    merchantID: '00000000-0000-0000-0000-000000000000',
    productFirstDescription: '',
    productLastDescription: '',
    status: true,
  };
  isLoading = false;

  constructor(
    private req: RequestService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.req.get('/Store/Gethepsiburadasettings').then((res) => {
      if (res.Data) {
        res.Data.status = res.Data.status + '';
        delete res.Data.id;
        delete res.Data.firmaID;
        delete res.Data.kayitTarihi;
        delete res.Data.olusturanKullaniciID;
        delete res.Data.sonGuncellemeTarihi;
        delete res.Data.sonGuncelleyenKullaniciID;
        res.Data.id = '00000000-0000-0000-0000-000000000000';
        res.Data.status = res.Data.status === 'true';
        this.data = res.Data;
      }
      this.isLoading = false;
    });
  }

  save() {
    this.isLoading = true;
    this.req.post('/Store/Savehepsiburadasettings', this.data).then((res) => {
      this.notify.show('Ayarlariniz basariyla kaydedildi.');
      this.isLoading = false;
    });
  }
}

interface HepsiburadaSettings {
  id: string;
  name: string;
  userName: string;
  password: string;
  merchantID: string;
  productFirstDescription: string;
  productLastDescription: string;
  status: boolean;
}
