import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { OrderDto, OrderFilter } from '../models/order';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private req: RequestService) {}

  async getAll(filter: OrderFilter | any): Promise<OrderDto[]> {
    return await this.req
      .get('/Order/getorders', filter)
      .then((res) => res.Data);
  }

  async get(id: string): Promise<OrderDto> {
    return await this.req.get(`/Order/${id}`);
  }

  async save(data: OrderDto): Promise<OrderDto> {
    return await this.req.post('/Order', data);
  }
}
