<form (submit)="save()" *ngIf="data">
  <fieldset class="row mt1">
    <!-- <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Satıcı Adı</mat-label>
        <input matInput name="Name" [(ngModel)]="data.name" #ctrl="ngModel" required />
      </mat-form-field>
    </div> -->
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Key</mat-label>
        <input matInput name="ApiKey" [(ngModel)]="data.apiKey" #ctrl="ngModel" required />
      </mat-form-field>
    </div>
    <!-- <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Secret</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.secretKey"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div> -->
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Teslimat Tipi</mat-label>

        <mat-select name="deliveryType" [(ngModel)]="data.deliveryType" #ctrl="ngModel" required>
          <mat-option [value]="1">Servis aracı ile gönderim</mat-option>
          <mat-option [value]="2">Kargo ile gönderim</mat-option>
          <mat-option [value]="3">Servis + Kargo ile gönderim</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Teslimat Tarihi</mat-label>



        <mat-select name="deliveryMessageType" [(ngModel)]="data.deliveryMessageType" #ctrl="ngModel" required>
          <mat-option *ngIf="data.deliveryType==1" [value]="1">Hediye servis</mat-option>
          <mat-option *ngIf="data.deliveryType==2" [value]="4">Hediye aynı gün kargo</mat-option>
          <mat-option *ngIf="data.deliveryType==2" [value]="5">Hediye kargo 1-3 iş günü</mat-option>
          <mat-option *ngIf="data.deliveryType==2" [value]="18">Hediye kargo 1-2 iş günü</mat-option>

          <mat-option *ngIf="data.deliveryType==3" [value]="1">Hediye servis</mat-option>
          <mat-option *ngIf="data.deliveryType==3" [value]="4">Hediye aynı gün kargo</mat-option>
          <mat-option *ngIf="data.deliveryType==3" [value]="5">Hediye kargo 1-3 iş günü</mat-option>
          <mat-option *ngIf="data.deliveryType==3" [value]="18">Hediye kargo 1-2 iş günü</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Mağaza Durumu</mat-label>

        <mat-select name="Status" [(ngModel)]="data.status" #ctrl="ngModel" required>
          <mat-option [value]="true"> Aktif </mat-option>
          <mat-option [value]="false"> Pasif </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Önü)</mat-label>
      <quill-editor style="width:100%" name="productFirstDescription" [(ngModel)]="data.productFirstDescription">
      </quill-editor>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Sonu)</mat-label>
      <quill-editor style="width:100%" name="productLastDescription" [(ngModel)]="data.productLastDescription">
      </quill-editor>
    </div>
  </fieldset>

  <app-divider></app-divider>

  <div class="row mt1">
    <div class="col-lg-12 col-md-12">
      <button mat-raised-button color="primary" class="w100" value="Submit">
        Ayarlari Kaydet
      </button>
    </div>
  </div>
</form>
