export class CustomerDto {
	public id: string = '';
	public firmaID: string = '';
	public kayitTarihi: string = '';
	public olusturanKullaniciID: string = '';
	public sonGuncellemeTarihi: string = '';
	public sonGuncelleyenKullaniciID: string = '';
	public unvani: string = '';
	public faturaUnvani: string = '';
	public kodu: string = '';
	public epostaAdresi: string = '';
	public telefon1: string = '';
	public telefon2: string = '';
	public iban: string = '';
	public vergiDaire: string = '';
	public vergiNo: string = '';
	public muhasebeKodu: string = '';
	public sehir: string = '';
	public kasaba: string = '';
	public sokak: string = '';
	public cadde: string = '';
	public mahalle: string = '';
	public bina: string = '';
	public kapi: string = '';
	public webAdresi: string = '';
	public eIslemTuru: string = '';
	public alias: string = '';
	public savedErp: string = '';
}