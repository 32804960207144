<app-title
  title="Magaza Ayarları"
  subtext="Magaza Ayarlarinizi buradan guncelleyebilirsiniz."
  icon="storesettings"
></app-title>

<mat-card class="col-lg-8">
  <mat-tab-group mat-align-tabs="center" animationDuration="0ms">
    <mat-tab label="N11">
      <div class="col-lg-12">
        <h3>N11 Mağaza Ayarları</h3>
        <app-n11settings></app-n11settings>
      </div>
    </mat-tab>
    <mat-tab label="Gittigidiyor">
      <div class="col-lg-12">
        <h3>GittiGidiyor Mağaza Ayarları</h3>
        <app-gittigidiyorsettings></app-gittigidiyorsettings>
      </div>
    </mat-tab>
    <mat-tab label="Hepsiburada">
      <div class="col-lg-12">
        <h3>Hepsiburada Mağaza Ayarları</h3>
        <app-hepsiburadasettings></app-hepsiburadasettings>
      </div>
    </mat-tab>
    <mat-tab label="Trendyol">
      <div class="col-lg-12">
        <h3>Trendyol Mağaza Ayarları</h3>
        <app-trendyolsettings></app-trendyolsettings>
      </div>
    </mat-tab>
    <mat-tab label="Çiçeksepeti">
      <div class="col-lg-12">
        <h3>Çiçek Sepeti Mağaza Ayarları</h3>
        <app-ciceksepetisettings></app-ciceksepetisettings>
      </div>
    </mat-tab>
    <mat-tab label="WooCommerce">
      <div class="col-lg-12">
        <h3>WooCommerce Mağaza Ayarları</h3>
        <app-wocommercesettings></app-wocommercesettings>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
<!--
<div class="row">
  <div class="col-lg-3">
    <mat-card class="row m0 p2 n11" (click)="changeState('n11')">
      <img src="../../../assets/images/n11-logo.png" />
    </mat-card>

    <mat-card
      class="row m0 mt1 p2 gittigidiyor"
      (click)="changeState('gittigidiyor')"
    >
      <img src="../../../assets/images/gittigidiyor-logo.png" />
    </mat-card>

    <mat-card
      class="row m0 mt1 p2 hepsiburada"
      (click)="changeState('hepsiburada')"
    >
      <img src="../../../assets/images/hepsiburada-logo.png" />
    </mat-card>

    <mat-card class="row m0 mt1 p2 trendyol">
      <img
        src="../../../assets/images/trendyol-logo.png"
        (click)="changeState('trendyol')"
      />
    </mat-card>
  </div>
  <div class="col-lg-9">
    <mat-card>
      <app-n11settings *ngIf="generalState.n11"></app-n11settings>
      <app-gittigidiyorsettings *ngIf="generalState.gittigidiyor">
      </app-gittigidiyorsettings>
      <app-trendyolsettings *ngIf="generalState.trendyol">
      </app-trendyolsettings>
      <app-hepsiburadasettings *ngIf="generalState.hepsiburada">
      </app-hepsiburadasettings>
    </mat-card>
  </div>
</div> -->
