<app-title
  title="Genel Ayarlar"
  subtext="Hesabınızla ilgili tüm ayaarları buradan yapabilirsiniz"
></app-title>

<form class="row">
  <div class="col-lg-8">
    <mat-card class="mb2 p2">
      <app-divider content="Firma Ayarları"></app-divider>
      <fieldset class="row mt1">
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>Logo</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>Firma Adı</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>Slogan</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>Telefon</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>E-Posta Adresi</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>Adres</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider content="Fatura Ayarları"></app-divider>
      <fieldset class="row mt1">
        <div class="col-lg-6 m0">
          <div class="row">
            <div class="col-lg-12 m0">
              <mat-form-field class="w100">
                <mat-label>Son Fatura No</mat-label>
                <input matInput placeholder="Maksimum 150 Karakter" />
              </mat-form-field>
            </div>
            <div class="col-lg-12 m0">
              <mat-form-field class="w100">
                <mat-label>Genel KDV Oranı</mat-label>
                <input matInput placeholder="Maksimum 150 Karakter" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-6 m0">
          <mat-checkbox class="display-block">
            Fatura No Takibi Yapılsın
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Onaylanan Siparişler Otomatik Faturlandırılsın mı ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Fatura kalemlerinde ürün isimleri maskelensin mi ?
          </mat-checkbox>
        </div>
      </fieldset>

      <app-divider content="Ürün Listesi Ayarları"></app-divider>
      <fieldset class="row mt1">
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>Ürünler Hangi Kurala Göre Sıralansın ?</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-6 m0">
          <mat-form-field class="w100">
            <mat-label>Ürünler Kaç Adet Listelensin ?</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider content="Pazaryeri Ayarları"></app-divider>
      <fieldset class="row mt1 mb1">
        <div class="col-lg-6 m0">
          <mat-checkbox class="display-block">
            N11 Siparişleri Otomatik Onaylansın mı ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Hepsiburada Onaylanmamış Siparişler Getirilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Akakçe Siparişleri Otomatik Onaylansın mı ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Gittigidiyor'da Süresi Biten Ürünler Otomatik Yeniden Listelensin mi
            ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Her Pazaryeri İçin Farklı Ürün Açıklaması Girilebilsin mi ?
          </mat-checkbox>
        </div>
      </fieldset>

      <app-divider content="Kargo Etiket Ayarları"></app-divider>
      <fieldset class="row mt1">
        <div class="col-lg-6 m0">
          <div class="row">
            <div class="col-lg-12 m0">
              <mat-form-field class="w100">
                <mat-label>Kargo Etiketinde Kaç Ürün Yazdırılsın ?</mat-label>
                <input matInput placeholder="Maksimum 150 Karakter" />
              </mat-form-field>
            </div>

            <div class="col-lg-12 m0">
              <mat-form-field class="w100">
                <mat-label>Toplu Kargo Etkiketi Font Büyüklüğü</mat-label>
                <input matInput placeholder="Maksimum 150 Karakter" />
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="col-lg-6 m0">
          <mat-checkbox class="display-block">
            Kargo Etiketinde Kalan Stok Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Kargo Etiketinde Fatura Bilgileri Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Kargo Etiketinde Ürün Fiyatı Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Fatura Listesinde Detaylı Sipariş Fişi Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Kargo Etiketinde Stok Kodu Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Kargo Etiketinde Gönderici Telefonu Gösterilsin mi ?
          </mat-checkbox>
          <mat-checkbox class="example-margin">
            Kargo Etiketinde Gönderici Adresi Gösterilsin mi ?
          </mat-checkbox>
        </div>
      </fieldset>

      <app-divider content="Fatura Açıklama Alanı Ayarları"></app-divider>
      <fieldset class="row mt1 mb1">
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>N11 Fatura Açıklaması</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>GITTIGIDIYOR Fatura Açıklaması</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>HEPSIBURADA Fatura Açıklaması</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>TRENDYOL Fatura Açıklaması</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>AKAKÇE Fatura Açıklaması</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
        <div class="col-lg-12 m0">
          <mat-form-field class="w100">
            <mat-label>KARGO Etiketi Özel Açıklama</mat-label>
            <input matInput placeholder="Maksimum 150 Karakter" />
          </mat-form-field>
        </div>
      </fieldset>
    </mat-card>
  </div>
</form>
