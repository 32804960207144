import { Component, OnInit } from '@angular/core';
import { ErpSettingsService } from 'src/app/controllers/erp-settings.service';
import { ErpSettingsDto } from 'src/app/models/erp-settings';

@Component({
  selector: 'app-erpsettings',
  templateUrl: './erpsettings.component.html',
  styleUrls: ['./erpsettings.component.scss'],
})
export class ErpsettingsComponent implements OnInit {
  data: ErpSettingsDto = new ErpSettingsDto();

  constructor(private controller: ErpSettingsService) {}

  ngOnInit(): void {
    this.controller.getAll().then((res: any[]) => {
      this.data = res[0];
    });
  }

  reGenerate() {
    this.controller.generatenewapikey().then((res) => {
      this.data = res;
    });
  }
}
