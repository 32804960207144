import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BrandComponent } from './pages/brand/brand.component';
import { BrandsComponent } from './pages/brands/brands.component';
import { CategoriesComponent } from './pages/categories/categories.component';
import { CategoryComponent } from './pages/category/category.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ErpsettingsComponent } from './pages/erpsettings/erpsettings.component';
import { InvoicesComponent } from './pages/invoices/invoices.component';
import { OrdersComponent } from './pages/orders/orders.component';
import { ProductComponent } from './pages/product/product.component';
import { ProductsComponent } from './pages/products/products.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { StoresettingsComponent } from './pages/storesettings/storesettings.component';
import { VariantgroupsComponent } from './pages/variantgroups/variantgroups.component';

const routes: Routes = [
  { path: '', component: DashboardComponent },
  { path: 'login', component: DashboardComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'customer/:id', component: CustomerComponent },
  { path: 'customers', component: CustomersComponent },
  { path: 'invoices', component: InvoicesComponent },
  { path: 'orders', component: OrdersComponent },
  { path: 'orders/:search', component: OrdersComponent },
  { path: 'products', component: ProductsComponent },
  { path: 'product/:id', component: ProductComponent },
  { path: 'product', component: ProductComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'categories', component: CategoriesComponent },
  { path: 'category/:id', component: CategoryComponent },
  { path: 'category', component: CategoryComponent },
  { path: 'brands', component: BrandsComponent },
  { path: 'brand/:id', component: BrandComponent },
  { path: 'brand', component: BrandComponent },
  { path: 'variantgroups', component: VariantgroupsComponent },
  { path: 'erpsettings', component: ErpsettingsComponent },
  { path: 'storesettings', component: StoresettingsComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
