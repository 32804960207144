import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
// import { ErpSettingsDto } from '../models/erp-settings';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private req: RequestService) {}

  async getDashboard() {
    return await this.req.get('/Store/getDashboard').then((res) => res.Data);
  }
}
