import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/helpers/notification.service';
import { RequestService } from 'src/app/helpers/request.service';

@Component({
  selector: 'app-gittigidiyorsettings',
  templateUrl: './gittigidiyorsettings.component.html',
  styleUrls: ['./gittigidiyorsettings.component.scss'],
})
export class GittigidiyorsettingsComponent implements OnInit {
  data: gittigidiyorSettings = {
    id: '00000000-0000-0000-0000-000000000000',
    name: '',
    apiKey: '',
    secretKey: '',
    roleName: '',
    rolePassword: '',
    status: true,
    shippingDate: '',
    shippingFee: '',
    location: '',
    cargoCompany: '',
    saleDay: 0,
    productFirstDescription: '',
    productLastDescription: '',
    shippingAmount:0
  };
  isLoading = false;
  cities:any;

  constructor(
    private req: RequestService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.req.get('/Store/getgittigidiyorsettings').then((res) => {
      if (res.Data) {
        res.Data.status = res.Data.status + '';
        delete res.Data.id;
        delete res.Data.firmaID;
        delete res.Data.kayitTarihi;
        delete res.Data.olusturanKullaniciID;
        delete res.Data.sonGuncellemeTarihi;
        delete res.Data.sonGuncelleyenKullaniciID;
        res.Data.id = '00000000-0000-0000-0000-000000000000';
        res.Data.status = res.Data.status === 'true';
        this.data = res.Data;
      }
      this.isLoading = false;
    });


    this.req.get('/category/getcities').then((res) => {
      if (res.Data) {
          this.cities=res.Data;
      }
    });
  }

  save() {
    this.isLoading = true;
    this.req.post('/Store/savegittigidiyorsettings', this.data).then((res) => {
      this.notify.show('Ayarlariniz basariyla kaydedildi.');
      this.isLoading = false;
    });
  }
}

interface gittigidiyorSettings {
  id: string;
  name: string;
  apiKey: string;
  secretKey: string;
  roleName: string;
  rolePassword: string;
  status: boolean;
  shippingDate: string;
  shippingFee: string;
  location: string;
  cargoCompany: string;
  saleDay: number;
  productFirstDescription: string;
  productLastDescription: string;
  shippingAmount:number;
}
