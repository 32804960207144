import { Injectable } from '@angular/core';
import { RequestService } from '../helpers/request.service';
import { BrandDto } from '../models/brand';
import { SelectOption } from '../models/select';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  constructor(private req: RequestService) {}

  validate(data: BrandDto): boolean | BrandDto {
    if (data.id === null) return false;
    if (data.sonGuncelleyenKullaniciID === null) return false;
    if (data.firmaID === null) return false;
    if (data.olusturanKullaniciID === null) return false;
    if (data.sonGuncellemeTarihi === null) return false;
    if (data.kayitTarihi === null) return false;

    return true;
  }

  async getAllBrands(): Promise<BrandDto[]> {
    return await this.req.get('/Brand/GetAll').then((res) => res.Data);
  }

  async getBrand(id: string): Promise<BrandDto> {
    return await this.req.get(`/Brand/${id}`).then((res) => res.Data);
  }

  async getBrandSelectbox(): Promise<SelectOption[]> {
    return await this.req.get('/Brand/GetAll').then(
      (res) => {
        const selectList: SelectOption[] = res.Data.map((x: any) => {
          const tempOption = new SelectOption();
          tempOption.name = x.name;
          tempOption.value = x.id;
          return tempOption;
        });
        return selectList;
      }
    );
  }

  async saveBrand(data: BrandDto): Promise<BrandDto> {
    return await this.req.post('/Brand', data);
  }

  async deleteBrand(id: string): Promise<boolean> {
    return await this.req.delete('/Brand', id)
  }
}
