<form (submit)="save()" *ngIf="data">
  <fieldset class="row mt1">
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Url</mat-label>
        <input
          matInput
          name="Url"
          [(ngModel)]="data.url"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Key</mat-label>
        <input
          matInput
          name="ApiKey"
          [(ngModel)]="data.apiKey"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Api Secret</mat-label>
        <input
          matInput
          name="SecretKey"
          [(ngModel)]="data.secretKey"
          #ctrl="ngModel"
          required
        />
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0">
      <mat-form-field class="w100">
        <mat-label>Mağaza Durumu</mat-label>

        <mat-select
          name="Status"
          [(ngModel)]="data.status"
          #ctrl="ngModel"
          required
        >
          <mat-option [value]="true"> Aktif </mat-option>
          <mat-option [value]="false"> Pasif </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Önü)</mat-label>
      <quill-editor style="width:100%" name="productFirstDescription" [(ngModel)]="data.productFirstDescription"></quill-editor>
    </div>
    <div class="col-lg-12 col-md-12 m0 mt1">
      <mat-label>Sabit Açıklama(Açıklama Sonu)</mat-label>
      <quill-editor style="width:100%" name="productLastDescription" [(ngModel)]="data.productLastDescription"></quill-editor>
    </div>
  </fieldset>

  <app-divider></app-divider>

  <div class="row mt1">
    <div class="col-lg-12 col-md-12">
      <button mat-raised-button color="primary" class="w100" value="Submit">
        Ayarlari Kaydet
      </button>
    </div>
  </div>
</form>
