import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerService } from 'src/app/controllers/customer.service';
import { LoadingService } from 'src/app/helpers/loading.service';
import { CustomerDto } from 'src/app/models/customer';

export interface DialogData {
  filter: string;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  selection = new SelectionModel<CustomerDto>(true, []);

  filter = {};

  displayedColumns: string[] = [
    'select',
    'name',
    'vergiDaire',
    'vergiNo',
    'adres',
    'action',
  ];

  dataSource = Array<CustomerDto>();

  constructor(private router: Router, private controller: CustomerService, private loadingService: LoadingService) {}

  ngOnInit(): void {
    this.getTableData();
  }

  getTableData() {
    this.loadingService.showSpinner();
    this.controller.getAll().then((res) => {
      this.dataSource = res;
      this.loadingService.hideSpinner();
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: CustomerDto): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  newButtonClicked(id: any): void {
    if (id) {
      this.router.navigateByUrl(`/customer/${id}`);
    } else {
      this.router.navigateByUrl('/customer');
    }
  }

  redirectToOrders(customerName: string) {
    this.router.navigate(['/orders', { CustomerName: customerName }]);
  }
}
