<div class="container">
  <form class="w100" (submit)='submit()'>
    <img class="logo" src="../../../assets/images/logo.png" />
    <div class="form-container">
      <mat-form-field class="w100">
        <mat-label>Kullanici Adi</mat-label>
        <input
          matInput
          type="email"
          placeholder="e-posta adresinizi girin"
          name="name"
          maxlength="150"
          [(ngModel)]="loginData.UserName" #ctrl="ngModel"
          required
        />
      </mat-form-field>
      <mat-form-field class="w100">
        <mat-label>Sifre</mat-label>
        <input
          matInput
          type="password"
          placeholder="******"
          name="name"
          maxlength="150"
          [(ngModel)]="loginData.Password" #ctrl="ngModel"
          required
        />
      </mat-form-field>
      <button mat-raised-button color="accent" class="w100" value="Submit">
        Giris Yap
      </button>
    </div>
    <!-- <div class="register-container">
      <span class="txt1"> Hesabiniz yok mu? </span>
      <a class="txt2" href="#"> Kayit Olun </a>
    </div> -->
  </form>
</div>
