import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';

export interface DialogData {
  filter: string;
}

export interface InvoiceTable {
  platform: string;
  customer: string;
  date: string;
  price: number;
}

const DATA: InvoiceTable[] = [
  {
    platform: 'N11',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'Hepsiburada',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'N11',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'N11',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'N11',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'N11',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'Hepsiburada',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'Hepsiburada',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'Hepsiburada',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
  {
    platform: 'Hepsiburada',
    customer: 'Lorem Upsum',
    date: new Date().toUTCString().substring(0, 10),
    price: 1500,
  },
];

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  selection = new SelectionModel<InvoiceTable>(true, []);

  filter = {};

  displayedColumns: string[] = [
    'select',
    'platform',
    'customer',
    'date',
    'price',
    'action',
  ];
  dataSource = DATA;

  constructor() {}

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row?: InvoiceTable): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  ngOnInit(): void {}
}
