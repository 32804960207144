<app-title
  title="Musteri Bilgileri"
  subtext="Musterinizin bilgilerini aşağıdaki formlar yardımıyla girebilirsiniz."
  icon="customer"
></app-title>

<form class="row">
  <div class="col-lg-8 col-md-12">
    <mat-card class="mb2 p2">
      <app-divider content="Genel Bilgiler"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Unvani</mat-label>
            <input
              matInput
              name="unvani"
              [(ngModel)]="data.unvani"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Fatura Unvani</mat-label>
            <input
              matInput
              name="faturaUnvani"
              [(ngModel)]="data.faturaUnvani"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Vergi Daire</mat-label>
            <input
              matInput
              name="vergiDaire"
              [(ngModel)]="data.vergiDaire"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Vergi No</mat-label>
            <input
              matInput
              name="vergiNo"
              [(ngModel)]="data.vergiNo"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>IBAN</mat-label>
            <input
              matInput
              name="iban"
              [(ngModel)]="data.iban"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Muhasebe Kodu</mat-label>
            <input
              matInput
              name="muhasebeKodu"
              [(ngModel)]="data.muhasebeKodu"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>


      <app-divider content="Iletisim Bilgileri"></app-divider>
      
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Telefon</mat-label>
            <input
              matInput
              name="telefon1"
              [(ngModel)]="data.telefon1"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Telefon 2</mat-label>
            <input
              matInput
              name="telefon2"
              [(ngModel)]="data.telefon2"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>E-Posta Adresi</mat-label>
            <input
              matInput
              name="epostaAdresi"
              [(ngModel)]="data.epostaAdresi"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>

      <app-divider content="Adres Bilgileri"></app-divider>

      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Il</mat-label>
            <input
              matInput
              name="sehir"
              [(ngModel)]="data.sehir"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Ilce</mat-label>
            <input
              matInput
              name="kasaba"
              [(ngModel)]="data.kasaba"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>

      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Mahalle</mat-label>
            <input
              matInput
              name="bina"
              [(ngModel)]="data.bina"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Cadde</mat-label>
            <input
              matInput
              name="cadde"
              [(ngModel)]="data.cadde"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Sokak</mat-label>
            <input
              matInput
              name="sokak"
              [(ngModel)]="data.sokak"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>

        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Bina</mat-label>
            <input
              matInput
              name="bina"
              [(ngModel)]="data.bina"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
      <fieldset class="row mt1">
        <div class="col-lg-6 col-md-12 m0">
          <mat-form-field class="w100">
            <mat-label>Kapi</mat-label>
            <input
              matInput
              name="kapi"
              [(ngModel)]="data.kapi"
              #ctrl="ngModel"
              readonly
            />
          </mat-form-field>
        </div>
      </fieldset>
    </mat-card>
  </div>
</form>