import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/helpers/notification.service';
import { RequestService } from 'src/app/helpers/request.service';

@Component({
  selector: 'app-ciceksepetisettings',
  templateUrl: './ciceksepetisettings.component.html',
  styleUrls: ['./ciceksepetisettings.component.scss'],
})
export class CicekSepetisettingsComponent implements OnInit {
  data: CicekSepetiSettings = {
    id: '00000000-0000-0000-0000-000000000000',
    apiKey: '',
    productFirstDescription: '',
    productLastDescription: '',
    status: true,
    deliveryType:0,
    deliveryMessageType:0
  };
  isLoading = false;
  templates: any;

  constructor(
    private req: RequestService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.req.get('/Store/GetCicekSepetisettings').then((res) => {
      if (res.Data) {
        res.Data.status = res.Data.status + '';
        delete res.Data.id;
        delete res.Data.firmaID;
        delete res.Data.kayitTarihi;
        delete res.Data.olusturanKullaniciID;
        delete res.Data.sonGuncellemeTarihi;
        delete res.Data.sonGuncelleyenKullaniciID;
        res.Data.id = '00000000-0000-0000-0000-000000000000';
        res.Data.status = res.Data.status === 'true';
        this.data = res.Data;
      }
      this.isLoading = false;
    });
  }

  save() {
    this.isLoading = true;
    this.req.post('/Store/SaveCicekSepetisettings', this.data).then((res) => {
      this.notify.show('Ayarlariniz basariyla kaydedildi.');
      this.isLoading = false;
    });
  }
}

interface CicekSepetiSettings {
  id: string;
  apiKey: string;
  status: boolean;
  productFirstDescription: string;
  productLastDescription: string;
  deliveryType:number;
  deliveryMessageType:number;
}
