import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/helpers/notification.service';
import { RequestService } from 'src/app/helpers/request.service';

@Component({
  selector: 'app-trendyolsettings',
  templateUrl: './trendyolsettings.component.html',
  styleUrls: ['./trendyolsettings.component.scss'],
})
export class TrendyolsettingsComponent implements OnInit {
  data: TrendyolSettings = {
    id: '00000000-0000-0000-0000-000000000000',
    supplierName: '',
    supplierId: 0,
    apiKey: '',
    secretKey: '',
    status: true,
    productFirstDescription: '',
    productLastDescription: '',
  };
  isLoading = false;

  constructor(
    private req: RequestService,
    private notify: NotificationService
  ) {}

  ngOnInit(): void {
    this.req.get('/Store/Gettrendyolsettings').then((res) => {
      if (res.Data) {
        res.Data.status = res.Data.status + '';
        delete res.Data.id;
        delete res.Data.firmaID;
        delete res.Data.kayitTarihi;
        delete res.Data.olusturanKullaniciID;
        delete res.Data.sonGuncellemeTarihi;
        delete res.Data.sonGuncelleyenKullaniciID;
        res.Data.id = '00000000-0000-0000-0000-000000000000';
        res.Data.status = res.Data.status === 'true';
        res.Data.supplierId = parseInt(res.Data.supplierId);
        this.data = res.Data;
      }
      this.isLoading = false;
    });
  }

  save() {
    this.isLoading = true;
    this.req.post('/Store/Savetrendyolsettings', this.data).then((res) => {
      this.notify.show('Ayarlariniz basariyla kaydedildi.');
      this.isLoading = false;
    });
  }
}

interface TrendyolSettings {
  id: string;
  supplierName: string;
  supplierId: number;
  apiKey: string;
  secretKey: string;
  status: boolean;
  productFirstDescription: string;
  productLastDescription: string;
}
