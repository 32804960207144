import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrandService } from 'src/app/controllers/brand.service';
import { CategoryService } from 'src/app/controllers/category.service';
import { NotificationService } from 'src/app/helpers/notification.service';
import { SelectOption, SelectOptionNumber } from 'src/app/models/select';
import { ProductService } from '../../controllers/product.service';
import {
  ProductDto,
  ProductPriceDto,
  ProductSaveDto,
} from '../../models/product';

import { EditorChangeContent, EditorChangeSelection } from 'ngx-quill'
import Quill from 'quill'

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  @ViewChild('newImageInput') newImageInput: ElementRef | undefined;

  data: ProductSaveDto = new ProductSaveDto();

  categories: SelectOption[] = [];
  brands: SelectOption[] = [];
  platforms: SelectOptionNumber[] = [];
  images: any[] = [];
  imagesTypes: any[] = [];
  prices: ProductPriceDto[] = [];
  newimage: any;

  detailedPricing: boolean = false;

  sub: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private controller: ProductService,
    private notification: NotificationService,
    private categoryController: CategoryService,
    private brandController: BrandService
  ) {}

  ngOnInit(): void {
    this.getOptions();
    this.sub = this.route.params.subscribe((params) => {
      if (params.id) {
        this.data.product.id = params.id;
        this.controller.getProduct(params.id).then((res) => {
          this.data = res;
          res.images?.forEach((image) => {
            this.images.push('data:image/jpeg;base64,' + image.base64Image);
            this.imagesTypes.push(image.imageType)
          });
          this.detailedPricing = res.prices.length ? true : false;
          this.priceHandler();
        });
      } else {
        this.priceHandler();
      }
    });
  }

  getOptions() {
    this.categoryController.getAllOptions().then((res) => {
      this.categories = res;
    });
    this.brandController.getBrandSelectbox().then((res) => {
      this.brands = res;
    });
  }

  priceHandler() {
    this.controller.getAllPlatforms().then((res) => {
      this.platforms = res;
      res.forEach((item) => {
        if (this.data.product.id != '00000000-0000-0000-0000-000000000000') {
          const tempPriceItem = this.data.prices.filter(
            (x) => x.pazarYeriID === (item.value ? item.value - 2 : 0)
          );
          if (tempPriceItem[0]) {
            this.prices.push({
              pazarYeriID: item.value ? item.value - 2 : 0,
              price: tempPriceItem[0].price,
              listPrice: tempPriceItem[0].listPrice,
            });
          } else {
            this.prices.push({
              pazarYeriID: item.value ? item.value - 2 : 0,
              price: 0,
              listPrice: 0,
            });
          }
        } else {
          this.prices.push({
            pazarYeriID: item.value ? item.value - 2 : 0,
            price: 0,
            listPrice: 0,
          });
        }
      });
    });
  }

  handleNewImage() {
    this.imagesTypes.push(this.newImageInput?.nativeElement.files[0].type);

    const reader = new FileReader();
    reader.readAsDataURL(this.newImageInput?.nativeElement.files[0]);
    reader.addEventListener(
      'load',
      () => {
        this.images.push(reader.result);
      },
      false
    );

    console.log(this.imagesTypes);
  }

  removeImage(index: number) {
    this.images.splice(index, 1);
    this.imagesTypes.splice(index,1);
  }

  save(): void {
    this.data.images = [];
    this.images.forEach((image, index) => {
      let tempImage = image;
      const tempIndex = tempImage.indexOf('base64,');
      tempImage = tempImage.substring(tempIndex + 7, tempImage.length);
      this.data.images?.push({ index, base64Image: tempImage,imageType:this.imagesTypes[index] });
    });
    this.data.prices = this.prices;
    this.controller.saveProduct(this.data).then((res) => {
      this.router.navigateByUrl('/products');
      this.notification.show('Ürün başarıyla kaydedildi!');
    });
  }
}
